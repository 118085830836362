<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab v-for="item in items" :key="item.link" :to="item.link">{{ item.text }}</v-tab>
    </v-tabs>

    <router-view />
  </div>
</template>

<script>
import i18n from '@/i18n'

export default {
  name: 'IrrigationHome',
  components: {
  },

  data: () => ({
    tab: null,
    items: [
      {
        text: i18n.t('irrigation.tab.overview'),
        link: '/irrigation/overview'
      },
      {
        text: i18n.t('irrigation.tab.targets'),
        link: '/irrigation/targets'
      },
      {
        text: i18n.t('irrigation.tab.sources'),
        link: '/irrigation/sources'
      },
      {
        text: i18n.t('irrigation.tab.history'),
        link: '/irrigation/history'
      }
    ]
  })
}
</script>
