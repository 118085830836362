<template>
  <div>
    <TargetsTable @add="showAddDialog = true" @edit="editSource" />
    <AddIrrigationTargetDialog :show="showAddDialog" @input="showAddDialog = false" />
    <EditIrrigationTargetDialog :show="showEditDialog" @close="showEditDialog = false" ref="editDialog" />
  </div>
</template>

<script>
import AddIrrigationTargetDialog from './AddIrrigationTargetDialog.vue'
import EditIrrigationTargetDialog from './EditIrrigationTargetDialog.vue'
import TargetsTable from './TargetsTable.vue'

export default {
  name: 'IrrigationTargets',
  components: { AddIrrigationTargetDialog, TargetsTable, EditIrrigationTargetDialog },
  data: () => ({
    showAddDialog: false,
    showEditDialog: false
  }),

  methods: {
    editSource (target) {
      console.log('edit source', target)
      this.$refs.editDialog.target = target
      this.showEditDialog = true
    }
  }
}
</script>
