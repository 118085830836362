<template>
  <div>
    <IrrigationChecklist />

    <ul>
      <li>Irrigation Alerts (missed items)</li>
      <li>Programming of an irrigation plan (manual execution or automated execution)</li>
      <li>Irrigation plan creates urgent tasks for humans</li>
      <li>Manually tracking open valves</li>
      <li>Schematics of water pipes and pumps</li>
      <li>Sensor data</li>
      <li>Control of valves</li>
    </ul>

  </div>
</template>

<script>
import IrrigationChecklist from './IrrigationChecklist.vue'

export default {
  name: 'IrrigationHome',
  components: {
    IrrigationChecklist
  }
}
</script>
