<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-form data-cy="form">
        <v-card-title>Edit Irrigation Source</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field data-cy="sourceName" label="Source Name" v-model="editedSource.sourceName" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-time-picker v-model="editedSource.startTime" ampm-in-title full-width format="24hr"></v-time-picker>
            </v-col>
            <v-col>
              <v-time-picker v-model="editedSource.endTime" ampm-in-title full-width format="24hr"></v-time-picker>
            </v-col>
          </v-row>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn data-cy="formCancel" color="secondary" text @click="showDialog = false">Cancel</v-btn>
            <v-btn data-cy="formSave" color="primary" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { useIrrigationStore } from '@/stores/irrigationSourcesStore'

export default {
  name: 'EditIrrigationSourceDialog',
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true
    },
    source: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    error: null,
    editedSource: {}
  }),

  watch: {
    source: {
      handler (value) {
        if (value) {
          this.editedSource = { ...value }
        } else {
          this.editedSource = {}
        }
      },
      immediate: true
    }
  },

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('close', value)
      }
    }
  },

  methods: {
    save () {
      useIrrigationStore().updateSource(this.editedSource, this)
        .then(() => {
          this.showDialog = false
        })
        .catch((error) => {
          this.error = error.message
        })
    }
  }
}
</script>
