<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-form data-cy="form">
        <v-card-title>Add Irrigation Target</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select label="Zone" v-model="target.zoneId" :items="zones" item-text="name"
                item-value="value"></v-select>
            </v-col>
            <v-col>
              <v-text-field data-cy="targetName" label="Target Name" v-model="target.targetName" />
            </v-col>
            <v-col>
              <v-select label="Irrigation Type" v-model="target.type" :items="irrigationTypes" item-text="name"
                item-value="value"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field data-cy="duration" label="Duration in minutes" v-model="target.durationInMinutes" />
            </v-col>
            <v-col>
              <v-text-field data-cy="daysBetweenIrrigationEvents" label="Days between"
                v-model="target.daysBetweenIrrigationEvents" />
            </v-col>
            <v-col>
              <v-select label="Source" v-model="target.sourceId" :items="sources" item-text="name"
                item-value="value"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox v-model="excludeDates" label="Exclude dates?"></v-checkbox>
            </v-col>
            <v-col>
              <v-text-field :disabled="!excludeDates" data-cy="excludeFromDate" label="Exclude From Date" type="date"
                v-model="target.excludeFromDate" />
            </v-col>
            <v-col>
              <v-text-field :disabled="!excludeDates" data-cy="excludeToDate" label="Exclude To Date" type="date"
                v-model="target.excludeToDate" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea data-cy="comment" label="Comment" auto-grow v-model="target.comment">
              </v-textarea>
            </v-col>
            <v-col>
              <v-text-field data-cy="weight" label="Weight to influence order" v-model="target.weight" />
            </v-col>
          </v-row>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn data-cy="formCancel" color="secondary" text @click="showDialog = false">Cancel</v-btn>
            <v-btn data-cy="formSave" color="primary" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddIrrigationSourceDialog',
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    error: null,
    target: {},
    zones: [],
    sources: [],
    excludeDates: false,
    irrigationTypes: [
      { name: 'Manual', value: 'MANUAL' },
      { name: 'Drip', value: 'DRIP' },
      { name: 'Sprinkler', value: 'SPRINKLER' },
      { name: 'Drip & Sprinkler', value: 'DRIP_AND_SPRINKLER' }
    ]
  }),
  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  beforeMount () {
    this.loadZones()
    this.loadSources()
  },

  methods: {
    loadZones () {
      fetch('/api/zones', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          const sortedZones = data.zones.sort((a, b) => a.zoneName.localeCompare(b.zoneName))
          this.zones = sortedZones.map(zone => ({
            name: zone.zoneName,
            value: zone.id
          }))
        })
    },

    loadSources () {
      fetch('/api/irrigation/sources', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          const sortedSources = data.sort((a, b) => a.sourceName.localeCompare(b.sourceName))
          this.sources = sortedSources.map(source => ({
            name: source.sourceName,
            value: source.id
          }))
        })
    },

    save () {
      // TODO use store
      fetch('/api/irrigation/targets', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.target)
      })
        .then(response => {
          if (response.ok) {
            this.showDialog = false
            this.$emit('reload')
          } else {
            response.json().then(data => {
              this.error = data.message
            })
          }
        })
    }
  }
}
</script>
