<template>
  <v-data-table :headers="headers" :items="filterTargets()" :item-class="rowClass">
    <template v-slot:top>
      <v-toolbar>
        <v-btn data-cy="addDialog" color="primary" dark @click="$emit('add')">{{ $t('irrigationTargets.add') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn-toggle v-model="filterPaused">
          <v-btn :value="true">Hide inactive</v-btn>
          <v-btn :value="false">Show inactive</v-btn>
        </v-btn-toggle>
      </v-toolbar>
    </template>
    <template v-slot:[`item.duration`]="{ item }">
      {{ formatDuration(item.durationInMinutes * 60) }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon v-if="item.paused" @click="togglePauseTarget(item)">mdi-play</v-icon>
      <v-icon v-else @click="togglePauseTarget(item)">mdi-pause</v-icon>

      <v-icon @click="$emit('edit', item)">mdi-pencil</v-icon>
      <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>

<style>
.paused {
  background-color: rgb(255, 187, 187);
}
</style>

<script>
import i18n from '@/i18n'
import { useIrrigationTargetsStore } from '@/stores/irrigationTargetsStore'
import formatting from '@/mixins/formatting'

export default {
  name: 'IrrigationTargetsTable',
  mixins: [formatting],

  data: () => ({
    headers: [
      { text: i18n.t('irrigationTargets.tableHeaders.zone'), value: 'zone.zoneName' },
      { text: i18n.t('irrigationTargets.tableHeaders.name'), align: 'start', value: 'targetName' },
      { text: i18n.t('irrigationTargets.tableHeaders.duration'), value: 'duration' },
      { text: i18n.t('irrigationTargets.tableHeaders.daysBetween'), value: 'daysBetweenIrrigationEvents' },
      { text: i18n.t('irrigationTargets.tableHeaders.irrigationType'), value: 'type' },
      { text: i18n.t('irrigationTargets.tableHeaders.source'), value: 'source.sourceName' },
      { text: i18n.t('irrigationTargets.tableHeaders.weight'), value: 'weight' },
      { text: i18n.t('terms.tableHeaders.actions'), value: 'actions', sortable: false }

    ],
    targets: [],
    filterPaused: true
  }),

  beforeMount () {
    const irrigationStore = useIrrigationTargetsStore()

    irrigationStore.fetchTargets(this)
      .then(() => {
        this.targets = irrigationStore.targets
      })
  },

  methods: {
    filterTargets () {
      if (this.filterPaused) {
        return this.targets.filter(target => !target.paused)
      } else {
        return this.targets
      }
    },

    rowClass (item) {
      if (item.paused) { return 'paused' }

      return ''
    },

    togglePauseTarget (item) {
      console.log('pauseTarget', item)
      const irrigationStore = useIrrigationTargetsStore()

      item.paused = !item.paused
      irrigationStore.updateTarget(item, this)
        .then(() => {
          console.log('done')
        })
    },

    deleteItem (item) {
      const irrigationStore = useIrrigationTargetsStore()

      irrigationStore.deleteTarget(item, this)
        .then(() => {
          this.targets = irrigationStore.targets
        })
    }
  }
}
</script>
