<template>
  <div>
    <div v-for="(record, index) in records" :key="index">
      <h1>Irrigation record for {{ record.recordDate }}</h1>
      <div>
        <h2>Plan</h2>
        <table>
          <thead>
            <tr>
              <th>When</th>
              <th>Zone</th>
              <th>Target</th>
              <th>Duration</th>
              <th>Last Irrigation</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(element, index) in record.plan" :key="index">
              <td>{{ formatDateTime(element.irrigationTime) }}</td>
              <td>{{ element.target.zone.zoneName }}</td>
              <td>{{ element.target.targetName }}</td>
              <td>{{ element.target.duration }}</td>
              <td>{{ formatDateTime(element.target.lastIrrigation) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>Events</h2>
        <table>
          <thead>
            <tr>
              <th>When</th>
              <th>Zone</th>
              <th>Target</th>
              <th>User</th>
              <th>Irrigated</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(event, index) in record.events" :key="index">
              <td>{{ formatDateTime(event.when) }}</td>
              <td>{{ event.target.zone.zoneName }}</td>
              <td>{{ event.target.targetName }}</td>
              <td>{{ event.username }}</td>
              <td> {{ event.irrigated }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>Missed targets</h2>
        <p v-if="!record.missedTargetsPresent">No targets have been missed</p>
        <table v-if="record.missedTargetsPresent">
          <thead>
            <tr>
              <th>Zone</th>
              <th>Target</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(target, index) in getMissedTargets(record)" :key="index">
              <td>{{ target.zone.zoneName }}</td>
              <td>{{ target.targetName }}</td>
              <td>{{ target.duration }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
H1 {
  margin-top: 1rem;
}
</style>

<script>
import formatting from '@/mixins/formatting'

export default {
  name: 'IrrigationHistory',
  mixins: [formatting],
  data: () => ({
    records: []
  }),
  beforeMount () {
    fetch('/api/irrigation/checklist/history', {
      headers: {
        Authorization: 'Bearer ' + `${this.$keycloak.token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        this.records = data
      })
  },

  methods: {
    getMissedTargets (record) {
      // TODO remove in a while
      if (record.missedTargets) { return record.missedTargets }
      if (record.unscheduledTargets) { return record.unscheduledTargets }
    }

  }
}
</script>
