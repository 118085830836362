import { defineStore } from 'pinia'

export const useIrrigationTargetsStore = defineStore({
  id: 'irrigationTargetsStore',
  state: () => ({
    targets: []
  }),
  actions: {
    fetchTargets (vueInstance) {
      return fetch('/api/irrigation/targets', {
        headers: {
          Authorization: 'Bearer ' + `${vueInstance.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(targets => {
          this.targets = targets
        })
    },
    createTarget (target, vueInstance) {
      return fetch('/api/irrigation/targets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + `${vueInstance.$keycloak.token}`
        },
        body: JSON.stringify(target)
      })
        .then(response => {
          if (response.status === 201) {
            this.targets.push(target)
          }
        })
    },
    updateTarget (target, vueInstance) {
      console.log('updateTarget', target)
      return fetch(`/api/irrigation/targets/${target.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + `${vueInstance.$keycloak.token}`
        },
        body: JSON.stringify(target)
      })
        .then(response => response.json())
        .then(updatedTarget => {
          const index = this.targets.findIndex(s => s.id === updatedTarget.id)
          this.targets.splice(index, 1, updatedTarget)
        })
    },
    deleteTarget (target, vueInstance) {
      return fetch(`/api/irrigation/targets/${target.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + `${vueInstance.$keycloak.token}`
        }
      })
        .then(() => {
          const index = this.targets.findIndex(s => s.id === target.id)
          this.targets.splice(index, 1)
        })
    }
  }
})
