<template>
  <div>
    <SourcesTable @add="showAddDialog = true" @edit="editSource" />
    <AddIrrigationSourceDialog :show="showAddDialog" @close="showAddDialog = false" />
    <EditIrrigationSourceDialog :show="showEditDialog" @close="showEditDialog = false" ref="editDialog" />
  </div>
</template>

<script>
import AddIrrigationSourceDialog from './AddIrrigationSourceDialog.vue'
import EditIrrigationSourceDialog from './EditIrrigationSourceDialog.vue'
import SourcesTable from './SourcesTable.vue'

export default {
  name: 'IrrigationSources',
  components: { SourcesTable, AddIrrigationSourceDialog, EditIrrigationSourceDialog },

  data: () => ({
    showAddDialog: false,
    showEditDialog: false
  }),

  methods: {
    editSource (source) {
      console.log('edit source', source)
      this.$refs.editDialog.source = source
      this.showEditDialog = true
    }
  }

}
</script>
