<template>
  <div>
    <h2>Irrigation Checklist</h2>
    <p>Irrigation for {{ formatDate(checklistDate) }}</p>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left hide-on-small-screen">{{ $t('irrigation.tableHeaders.time') }}</th>
            <th class="text-left">{{ $t('irrigation.tableHeaders.zone') }}</th>
            <th class="text-left">{{ $t('irrigation.tableHeaders.object') }}</th>
            <th class="text-left hide-on-small-screen">{{ $t('irrigation.tableHeaders.irrigationType') }}</th>
            <th class="text-left">{{ $t('irrigation.tableHeaders.duration') }}</th>
            <th class="text-left">{{ $t('terms.tableHeaders.actions') }}</th>
            <th class="text-left hide-on-small-screen">{{ $t('irrigation.tableHeaders.actualDuration') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="element in irrigationItems" :key="element.targetId"
            :class="{ 'irrigated': hasBeenIrrigated(element) }">
            <td class="hide-on-small-screen">{{ element.irrigationTime }}</td>
            <td>{{ element.zoneName }} <br /><span class="source-name">({{ element.sourceName }})</span></td>
            <td>{{ element.targetName }}</td>
            <td class="hide-on-small-screen">{{ element.irrigationType }}</td>
            <td>{{ formatDuration(element.durationInSeconds) }} <span class="hide-on-big-screen">/ {{
              formatDuration(element.actualDurationInSeconds)
            }}</span></td>
            <td>
              <span v-if="!element.rainEvent">
                <v-icon large v-if="element.irrigationInProgress" color="secondary"
                  @click="stopIrrigation(element)">mdi-stop</v-icon>
                <v-icon large v-else color="primary" @click="startIrrigation(element)">mdi-play</v-icon>
              </span>
              <v-icon large @click="rainEvent(element)" color="primary">mdi-weather-pouring</v-icon>
            </td>
            <td class="hide-on-small-screen">{{ formatDuration(element.actualDurationInSeconds) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-alert v-if="infoAlert" type="info" outlined transition="fade-transition">
      Irrigation event by {{ $keycloak.fullName }} at {{ formatDateTime(new Date()) }}
    </v-alert>
  </div>
</template>

<style scoped>
.source-name {
  color: grey;
}

.irrigated {
  background-color: #eafbed;
}

@media screen and (max-width: 440px) {
  .hide-on-small-screen {
    display: none;
  }
}

@media screen and (min-width: 441px) {
  .hide-on-big-screen {
    display: none;
  }
}
</style>

<script>
import formatting from '@/mixins/formatting'

export default {
  name: 'IrrigationChecklist',
  components: {
  },
  mixins: [formatting],
  data: () => ({
    checklistDate: new Date(),
    infoAlert: false,
    irrigationItems: [],
    runningTimers: []
  }),

  beforeMount () {
    this.loadPlan()
  },

  mounted () {
    window.addEventListener('focus', () => {
      console.log('focus')
      this.loadPlan()
    })
  },

  methods: {
    hasBeenIrrigated (element) {
      return element.irrigated
    },

    loadPlan () {
      fetch('/api/irrigation/checklist', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.irrigationItems = data.checklistItems.map(item => ({
            ...item,
            intervalId: null
          }))

          this.irrigationItems.forEach(element => {
            if (element.irrigationInProgress) {
              this.startTimer(element)
            }
          })
        })
    },

    startTimer (element) {
      element.intervalId = setInterval(() => {
        element.actualDurationInSeconds++
      }, 1000)
      this.runningTimers.push(element)
    },

    stopTimer (element) {
      const timerToStop = this.runningTimers.filter(timer => timer.targetId === element.targetId)[0]
      if (!timerToStop) {
        return
      }
      clearInterval(timerToStop.intervalId)
      this.runningTimers.splice(this.runningTimers.indexOf(timerToStop), 1)
    },

    startIrrigation (element) {
      this.startTimer(element)
      this.toggleStartStop(element, true)
    },

    stopIrrigation (element) {
      this.stopTimer(element)
      this.toggleStartStop(element, false)
    },

    callAPI (element, irrigationRecord) {
      fetch(`/api/irrigation/checklist/${element.targetId}`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(irrigationRecord)
      })
        .then(response => {
          if (response.status === 200) {
            this.loadPlan()
          }
        })

      this.infoAlert = true
      setTimeout(() => {
        this.infoAlert = false
      }, 2500)
    },

    toggleStartStop (element, irrigationStatus) {
      this.callAPI(element, {
        irrigated: irrigationStatus,
        username: this.$keycloak.fullName
      })
    },

    rainEvent (element) {
      this.callAPI(element, {
        irrigated: true,
        rainEvent: true,
        username: this.$keycloak.fullName
      })
    }
  }
}
</script>
