<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-form data-cy="form">
        <v-card-title>Add Irrigation Source</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field data-cy="sourceName" label="Source Name" v-model="source.sourceName" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-time-picker v-model="source.startTime" ampm-in-title full-width format="24hr"></v-time-picker>
            </v-col>
            <v-col>
              <v-time-picker v-model="source.endTime" ampm-in-title full-width format="24hr"></v-time-picker>
            </v-col>
          </v-row>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn data-cy="formCancel" color="secondary" text @click="showDialog = false">Cancel</v-btn>
            <v-btn data-cy="formSave" color="primary" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { useIrrigationStore } from '@/stores/irrigationSourcesStore'

export default {
  name: 'AddIrrigationSourceDialog',
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    error: null,
    source: {}
  }),
  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        if (value === false) { this.$emit('close') }
      }
    }
  },
  methods: {
    save () {
      const store = useIrrigationStore()
      store.createSource(this.source, this)
        .then(() => {
          this.showDialog = false
        }).catch((error) => {
          this.error = error
        })
    }
  }
}
</script>
