<template>
  <v-data-table :headers="headers" :items="sources">
    <template v-slot:top>
      <v-toolbar>
        <v-btn data-cy="addDialog" color="primary" dark @click="$emit('add')">{{ $t('irrigationSources.add') }}</v-btn>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon @click="$emit('edit', item)">mdi-pencil</v-icon>
      <v-icon disabled>mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import i18n from '@/i18n'
import { useIrrigationStore } from '@/stores/irrigationSourcesStore'

export default {
  name: 'IrrigationSourcesTable',
  data: () => ({
    headers: [
      { text: i18n.t('irrigationSources.tableHeaders.name'), align: 'start', value: 'sourceName' },
      { text: i18n.t('irrigationSources.tableHeaders.startTime'), value: 'startTime' },
      { text: i18n.t('irrigationSources.tableHeaders.endTime'), value: 'endTime' },
      { text: i18n.t('terms.tableHeaders.actions'), value: 'actions', sortable: false }
    ],
    sources: []
  }),

  beforeMount () {
    const irrigationStore = useIrrigationStore()

    irrigationStore.fetchSources(this)
      .then(() => {
        this.sources = irrigationStore.sources
      })
  }
}
</script>
