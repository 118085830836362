<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-form data-cy="form">
        <v-card-title>Edit Irrigation Target</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field data-cy="targetName" label="Target Name" v-model="editedTarget.targetName" />
            </v-col>
            <v-col>
              <v-select label="Irrigation Type" v-model="editedTarget.type" :items="irrigationTypes" item-text="name"
                item-value="value"></v-select>
            </v-col>
            <v-col>
              <v-text-field data-cy="duration" label="Duration in minutes" v-model="editedTarget.durationInMinutes" />
            </v-col>
            <v-col>
              <v-text-field data-cy="daysBetweenIrrigationEvents" label="Days between"
                v-model="editedTarget.daysBetweenIrrigationEvents" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox v-model="excludeDates" label="Exclude dates?"></v-checkbox>
              <v-checkbox v-model="editedTarget.paused" label="Paused?"></v-checkbox>
            </v-col>
            <v-col>
              <v-text-field :disabled="!excludeDates" data-cy="excludeFromDate" label="Exclude From Date" type="date"
                v-model="editedTarget.excludeFromDate" />
            </v-col>
            <v-col>
              <v-text-field :disabled="!excludeDates" data-cy="excludeToDate" label="Exclude To Date" type="date"
                v-model="editedTarget.excludeToDate" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea data-cy="comment" label="Comment" auto-grow v-model="editedTarget.comment">
              </v-textarea>
            </v-col>
            <v-col>
              <v-text-field data-cy="weight" label="Weight to influence order" v-model="editedTarget.weight" />
            </v-col>
          </v-row>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn data-cy="formCancel" color="secondary" text @click="showDialog = false">Cancel</v-btn>
            <v-btn data-cy="formSave" color="primary" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { useIrrigationTargetsStore } from '@/stores/irrigationTargetsStore'

export default {
  name: 'EditIrrigationTargetDialog',
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true
    },
    target: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    error: null,
    excludeDates: false,
    irrigationTypes: [
      { name: 'Manual', value: 'MANUAL' },
      { name: 'Drip', value: 'DRIP' },
      { name: 'Sprinkler', value: 'SPRINKLER' },
      { name: 'Drip & Sprinkler', value: 'DRIP_AND_SPRINKLER' }
    ],
    editedTarget: {}
  }),

  watch: {
    target: {
      handler (value) {
        if (value) {
          this.editedTarget = { ...value }
          this.excludeDates = !!value.excludeFromDate || !!value.excludeToDate
        } else {
          this.editedTarget = {}
        }
      },
      immediate: true
    }
  },

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('close', value)
      }
    }
  },

  methods: {
    save () {
      useIrrigationTargetsStore().updateTarget(this.editedTarget, this)
        .then(() => {
          this.showDialog = false
        })
        .catch((error) => {
          this.error = error.message
        })
    }
  }
}
</script>
