import { defineStore } from 'pinia'

export const useIrrigationStore = defineStore({
  id: 'irrigationSources',
  state: () => ({
    sources: []
  }),
  actions: {
    fetchSources (vueInstance) {
      return fetch('/api/irrigation/sources', {
        headers: {
          Authorization: 'Bearer ' + `${vueInstance.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(sources => {
          this.sources = sources
        })
    },
    createSource (source, vueInstance) {
      return fetch('/api/irrigation/sources', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + `${vueInstance.$keycloak.token}`
        },
        body: JSON.stringify(source)
      })
        .then(response => {
          if (response.status === 201) {
            this.sources.push(source)
          }
        })
    },
    updateSource (source, vueInstance) {
      return fetch(`/api/irrigation/sources/${source.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + `${vueInstance.$keycloak.token}`
        },
        body: JSON.stringify(source)
      })
        .then(response => response.json())
        .then(updatedSource => {
          const index = this.sources.findIndex(s => s.id === updatedSource.id)
          this.sources.splice(index, 1, updatedSource)
        })
    },
    deleteSource (source, vueInstance) {
      return fetch(`/api/irrigation/sources/${source.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + `${vueInstance.$keycloak.token}`
        }
      })
        .then(() => {
          const index = this.sources.findIndex(s => s.id === source.id)
          this.sources.splice(index, 1)
        })
    }
  }
})
